<template>
  <div class="payment-section">
    <bg-text size="title-4" class="payment-section__title mb-16">
      Informasi Kontrak
    </bg-text>

    <bg-grid>
      <bg-grid-item :col="6">
        <add-tenant-field label="Kategori Kontrak DBET" rules="required">
          <bg-select
            placeholder="Pilih kategori kontrak DBET"
            data-testid="categorySelect_ddl"
            v-model="category"
            :options="categoryOptions"
          />
        </add-tenant-field>
      </bg-grid-item>

      <bg-grid-item :col="6">
        <add-tenant-contract-id-input
          v-model="contractId"
          placeholder="Masukkan contract ID"
          :required="isRequiredContractId"
          :disabled="isDisabledContractIdInput"
          @select="selectContract"
        />
      </bg-grid-item>

      <bg-grid-item v-if="isShowNote" :col="6">
        <add-tenant-field
          label="Catatan"
          label-for="inputNote_txt"
          rules="required|min:5|max:300"
        >
          <bg-textarea
            id="inputNote_txt"
            v-model="note"
            rows="3"
            placeholder="Isi catatan di sini"
            :max-char="300"
          />
        </add-tenant-field>
      </bg-grid-item>
    </bg-grid>
  </div>
</template>

<script>
import { computed, ref, watch } from '@vue/composition-api';
import { BgGrid, BgGridItem, BgSelect, BgText, BgTextarea } from 'bangul-vue';
import {
  DBET_CONTRACT_CATEGORY,
  DBET_CONTRACT_CATEGORY_OPTIONS,
} from '@admin/constants/dbetContractCategory';

import AddTenantField from '@admin/pages/RoomAllotmentAddTenant/components/AddTenantField';
import AddTenantContractIdInput from '@admin/pages/RoomAllotmentAddTenant/components/AddTenantContractIdInput/AddTenantContractIdInput.vue';

export default {
  name: 'DbetContractInfoSection',

  components: {
    BgGrid,
    BgGridItem,
    BgSelect,
    BgText,
    BgTextarea,
    AddTenantField,
    AddTenantContractIdInput,
  },

  props: {
    contractNote: {
      type: String,
      default: '',
    },
    contractCategory: {
      type: String,
      default: '',
    },
  },

  setup(props, { emit }) {
    const category = ref(props.contractCategory);
    const categoryOptions = DBET_CONTRACT_CATEGORY_OPTIONS;

    const selectedCategoryData = computed(
      () => DBET_CONTRACT_CATEGORY[category.value]
    );

    const isRequiredContractId = computed(
      () => !category.value || !!selectedCategoryData.value?.contractIdRequired
    );

    const isDisabledContractIdInput = computed(
      () => !category.value || !!selectedCategoryData.value?.contractIdDisabled
    );

    const note = ref('');
    const isShowNote = computed(
      () => DBET_CONTRACT_CATEGORY[category.value]?.noteRequired
    );

    watch(note, value => {
      emit('update:contract-note', value);
    });

    const contractId = ref('');
    const contract = ref(null);

    const selectContract = contractData => {
      contract.value = isDisabledContractIdInput.value ? null : contractData;
    };

    watch(
      contract,
      value => {
        emit('contract-changed', value);
      },
      { deep: true }
    );

    watch(category, value => {
      if (isDisabledContractIdInput.value) {
        contractId.value = '';
        contract.value = null;
      }

      note.value = '';

      emit('update:contract-category', value);
    });

    return {
      category,
      categoryOptions,
      contractId,
      isRequiredContractId,
      isDisabledContractIdInput,
      selectContract,
      note,
      isShowNote,
    };
  },
};
</script>
