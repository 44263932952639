<template>
  <div class="payment-section">
    <bg-text size="title-4" class="payment-section__title mb-16">
      Informasi Pembayaran
    </bg-text>

    <validation-observer ref="observer">
      <bg-grid>
        <bg-grid-item :col="6">
          <add-tenant-date-field
            v-model="startDate"
            id="startDateInput"
            label="Tanggal Mulai Sewa"
            :disabled="disabled"
          />
        </bg-grid-item>

        <bg-grid-item :col="6">
          <add-tenant-rent-type-field
            v-model="rentType"
            :disabled="disabled"
            :options="rentTypeOptions"
          />
        </bg-grid-item>

        <bg-grid-item :col="6">
          <add-tenant-rent-duration-field
            v-model="rentDuration"
            :rent-type="rentType"
            :disabled="isDisabledDurationSelect"
          />
        </bg-grid-item>

        <bg-grid-item :col="6">
          <add-tenant-date-field
            :value="dueDate"
            id="dueDateInput"
            label="Tanggal Jatuh Tempo"
            message="Tanggal penagihan uang sewa berikutnya"
            disabled
          />
        </bg-grid-item>

        <bg-grid-item :col="6">
          <add-tenant-room-select-field
            v-model="selectedRoomId"
            :initial-room-id="initialRoomId"
            :checkin-date="startDate"
            :rent-type="rentType"
            :duration="rentDuration"
            :listing-id="listingId"
            :disabled="isDisabledRoomSelect"
            @room-changed="$emit('room-changed', $event)"
          />
        </bg-grid-item>

        <bg-grid-item :col="6">
          <add-tenant-rent-price-field :value="rentPrice" />
        </bg-grid-item>
      </bg-grid>
    </validation-observer>
  </div>
</template>

<script>
import _some from 'lodash/some';
import { BgText, BgGrid, BgGridItem } from 'bangul-vue';
import { ValidationObserver } from 'vee-validate';

import { getCheckoutDateEstimation } from '@admin/pages/RoomAllotmentAddTenant/utils/checkoutDate';
import { dateFormatterToSend } from 'Utils/formatter';

import { RENT_DURATION } from '@admin/pages/RoomAllotmentAddTenant/constants/BookingData';

import AddTenantDateField from '@admin/pages/RoomAllotmentAddTenant/components/fields/AddTenantDateField';
import AddTenantRentDurationField from '@admin/pages/RoomAllotmentAddTenant/components/fields/AddTenantRentDurationField';
import AddTenantRentPriceField from '@admin/pages/RoomAllotmentAddTenant/components/fields/AddTenantRentPriceField';
import AddTenantRentTypeField from '@admin/pages/RoomAllotmentAddTenant/components/fields/AddTenantRentTypeField';
import AddTenantRoomSelectField from '@admin/pages/RoomAllotmentAddTenant/components/fields/AddTenantRoomSelectField';

export default {
  name: 'DbetPaymentSection',

  components: {
    BgText,
    BgGrid,
    BgGridItem,
    ValidationObserver,
    AddTenantDateField,
    AddTenantRentDurationField,
    AddTenantRentPriceField,
    AddTenantRentTypeField,
    AddTenantRoomSelectField,
  },
  props: {
    initialData: {
      type: Object,
      default: () => ({}),
    },
    listingId: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rentPrice: {
      type: Number,
      default: 0,
    },
    rentTypeOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      rentType: '',
      startDate: null,
      rentDuration: 0,
      selectedRoomId: '',
      initialRoomId: '',
    };
  },
  watch: {
    listingId() {
      this.rentType = '';
      this.rentDuration = 0;
      this.startDate = null;
      this.initialRoomId = '';
      this.resetValidation();
    },
    startDate() {
      this.rentDuration = 0;
    },
    rentType(rentType) {
      this.$emit('rent-type-changed', rentType);
      this.rentDuration = 0;
    },
    rentDuration() {
      this.selectedRoomId = 0;
    },
    initialData: {
      deep: true,
      handler(rentData) {
        if (this.listingId) {
          this.setAutofillRentData(rentData);
        }
      },
    },
  },
  computed: {
    isDisabledDurationSelect() {
      return this.disabled || !this.startDate || !this.rentType;
    },
    isDisabledRoomSelect() {
      return (
        this.disabled || this.isDisabledDurationSelect || !this.rentDuration
      );
    },
    dueDate() {
      return getCheckoutDateEstimation({
        checkinDate: this.startDate,
        rentCountType: this.rentType,
        duration: 1,
      });
    },
  },
  methods: {
    resetValidation() {
      this.$refs.observer.reset();
    },
    async setAutofillRentData(rentData) {
      this.startDate = rentData.startDate || null;

      this.rentType = _some(this.rentTypeOptions, { val: rentData.rentType })
        ? rentData.rentType
        : '';

      await this.$nextTick();

      this.rentDuration =
        this.rentType &&
        _some(RENT_DURATION[this.rentType], { val: rentData.rentDuration })
          ? rentData.rentDuration
          : 0;

      this.initialRoomId = rentData.selectedRoomId || '';
    },
    // Called from parent to get all inputed values in this component
    getValues() {
      return {
        rent_type: this.rentType,
        duration: this.rentDuration,
        designer_room_id: this.selectedRoomId,
        start_date: dateFormatterToSend(this.startDate),
      };
    },
  },
};
</script>
