export const DBET_CONTRACT_CATEGORY = {
  bse_workaround: {
    value: 'bse_workaround',
    contractIdRequired: false,
    contractIdDisabled: true,
    noteRequired: true,
    tenantInfoDisabled: false,
  },
  billing_change_due_date: {
    value: 'billing_change_due_date',
    contractIdRequired: true,
    contractIdDisabled: false,
    noteRequired: false,
    tenantInfoDisabled: true,
  },
  billing_change_rental_duration: {
    value: 'billing_change_rental_duration',
    contractIdRequired: true,
    contractIdDisabled: false,
    noteRequired: false,
    tenantInfoDisabled: true,
  },
  billing_incident: {
    value: 'billing_incident',
    contractIdRequired: true,
    contractIdDisabled: false,
    noteRequired: false,
    tenantInfoDisabled: true,
  },
  billing_change_room_type: {
    value: 'billing_change_room_type',
    contractIdRequired: true,
    contractIdDisabled: false,
    noteRequired: false,
    tenantInfoDisabled: true,
  },
  move_property: {
    value: 'move_property',
    contractIdRequired: false,
    contractIdDisabled: false,
    noteRequired: false,
    tenantInfoDisabled: false,
  },
};

export const DBET_CONTRACT_CATEGORY_OPTIONS = [
  {
    label: 'BSE - BSE Workaround',
    val: DBET_CONTRACT_CATEGORY.bse_workaround.value,
  },
  {
    label: 'Billing - Ubah Jatuh Tempo',
    val: DBET_CONTRACT_CATEGORY.billing_change_due_date.value,
  },
  {
    label: 'Billing - Ubah Durasi Sewa',
    val: DBET_CONTRACT_CATEGORY.billing_change_rental_duration.value,
  },
  {
    label: 'Billing - Incident',
    val: DBET_CONTRACT_CATEGORY.billing_incident.value,
  },
  {
    label: 'Billing - Pindah Tipe Kamar',
    val: DBET_CONTRACT_CATEGORY.billing_change_room_type.value,
  },
  {
    label: 'Pindah Properti',
    val: DBET_CONTRACT_CATEGORY.move_property.value,
  },
];
